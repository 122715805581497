<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Сегодня займемся самым приятным, начнем работать с пластиком )))</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Трафареты</li>
                <li>Заготовки</li>
                <li>Пластик LaDoll</li>
                <li>Рулон пищевой пленки</li>
                <li>Синтетическая кисть (плоская №4-6)</li>
                <li>Макетный нож</li>
                <li>Палочки для суши</li>
                <li>Коктейльные трубочки</li>
                <li>Стакан с водой</li>
                <li>Стек-гладилка</li>
                <li>Линейка с круглыми отверстиями</li>
            </ul>
            <h3>1. Подготовка</h3>
        </div>

        <VideoView video-id="731fc822fb6c4f3c941df15820180aae"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Обтяните пластилиновые заготовки (голова и грудная клетка) пищевой пленкой</li>
                <li>Расширьте трафареты (головы и тела) до оригинальных контуров</li>
            </ol>
            
            <h3>2. О пластике LaDoll</h3>
        </div>

        <VideoView video-id="1ba8d47100314851a340184d29b19874"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Техника безопасности при работе с пластиком</b></p>
            <ol>
                <li>Чаще вытирать руки влажным полотенцем или работать в перчатках, обязательно после работы покрывать руки жирным кремом</li>
                <li>При работе пластик накрывайте влажным полотенцем, чтобы он не подвертивался. Когда закончили работать, замотайте пачку плотно в пищевую пленку и храните подальше от батарей и холодильника, просто при комнатной температуре.</li>
                <li>Для защиты легких можно работать в маске, можно постелить влажное полотенце на рабочее место или мисочку с водой.</li>
                <li>Не рекомендуется перевозить пластик в холодное время года.</li>
                <li>Если пластик суховат, разомните его с водой. Если целая пачка подсохла, снимите всю обертку и заверните пластик во влажные полотенца, потом в пленку, оставьте на сутки, раскройте и помесите, использовать стоит сразу ) </li>
                <li>Работать только на чистой поверхности, чистыми руками!</li>
            </ol>

            <h3>2. Обтяните пластиком голову </h3>
        </div>

        <VideoView video-id="3886e078fac44189bfc772a815e8c62e"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Раскатайте пласт толщиной 5 мм</li>
                <li>Смочите заготовку, чтобы было плотное сцепление пластика с заготовкой</li>
                <li>Аккуратно обтяните заготовку пластиком, сохраняя толщину пласта в 5мм (это важно!). Не торопитесь, это медитативный процесс</li>
                <li>На местах стыка пластика, обильно смочите водой, чтобы соединить пластик и избежать образования трещин при высыхании</li>
                <li>Сделайте отверстие сверху и снизу (15мм диаметром) по центру шпажки </li>
                <li>Проверьте голову по чек-листу</li>
                <li>Оставляем голову сушиться на сутки, небольше!!! Иначе будет сложно разрезать и доставать пластилин</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/head2.jpg')" class="checklist" :class="$mq">

                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
            
            <h3>3. Обтяните пластиком верхнюю часть тела</h3>
        </div>

        <VideoView video-id="282e7a0ba3134aeba25f80cc26a134e9"/>
            
        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Раскатайте пласт толщиной 5 мм</li>
                <li>Смочите заготовку, чтобы было плотное сцепление пластика с заготовкой</li>
                <li>Аккуратно обтяните заготовку пластиком, сохраняя толщину пласта в 5мм (это важно!). Не торопитесь, это медитативный процесс</li>
                <li>На местах стыка пластика, обильно смочите водой, чтобы соединить пластик и избежать образования трещин при высыхании</li>
                <li>Проверьте грудь по чек-листу</li>
                <li>Оставляем грудь сушиться на сутки, небольше!!! Иначе будет сложно разрезать и доставать пластилин</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/body2.jpg')" class="checklist" :class="$mq">

                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-body2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>3. Обтяните пластиком нижнюю часть тела</h3>
        </div>

         <VideoView video-id="f492850df5b149f9a2506464cc6d679d"/>
         
        <div class="MBtextcont">
            <p>Нижнюю часть тела мы вскрывать не будем, поэтому просто оставляем ее до полного высыхания</p>
            <h3>3. Обтяните пластиком ноги</h3>
        </div>

         <VideoView video-id="90eb0581f412491a82d03e2c837a4aa6"/>

        <div class="MBtextcont">
            <h3>3. Обтяните пластиком руки</h3>
        </div>

         <VideoView video-id="5cb8b53d995347b2b9c59439d8a34999"/>

        <div class="MBtextcont">
            <h3>4. Через сутки вскрываем голову и грудь</h3>
        </div>

         <VideoView video-id="1750c9ee44634ebb9d6479a1833f2b2d"/>


        <div class="MBtextcont">
            <p>Соединяем только грудь, голову не трогаем.</p>
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Намечаем линии разреза по бокам (по середине заготовки)</li>
                <li>Аккуратно макетным ножом разрезаем, расшатываем стенки </li>
                <li>Достаем пластилин</li>
                <li>Проверяем толщину стенки (не менее 5 мм) на всей поверхности заготовки, если нужно укрепляем дополнительным слоем пластика (особенно вверху головы)</li>
                <li>Проверяем заготовки на трещины, если нужно расширяем, увлажняем и замазываем</li>
                <li>Соединяем только тело, голову оставляем открытой</li>
            </ol>
            <div class="summary"></div>
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li><b>Обтянуть голову</b> + проверить по чек-листу (оставляем сохнуть сутки!), вскрыть</li>
                <li><b>Обтянуть верхнюю часть тела</b> + проверить по чек-листу (оставляем сохнуть сутки!), вскрыть, соединить</li>
                <li><b>Обтянуть нижнюю часть тела</b></li>
                <li><b>Обтянуть ноги и руки</b></li>
                <li><b>Подготовить материалы</b> к следующему занятию: скрепки нерефленные 5см, круглогубцы, плоскогубцы, кусачки (опционально), ватный диск, клей ПВА столяр + кисть, шкурка средней зернистости, стек-клюшка, пластырь на тканной основе</li>
            </ol>
            <h3><a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></h3>
           <h3>Время выполнения: 3ч + сутки ожидания </h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js'
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src) {
                this.$swal.fire({
                    // width: '80%',
                    height: '90%',
                    background: '#eae2e0',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>